 (function($, root, undefined) {

   //Afegeixo la classe als elements que han de tenir efecte hover
   $('a, button, .grid-item, .grid-item-projecte, .ampliable, .swiper-button-next, .swiper-button-prev').addClass('k-hover');

   //Transicions
   /*
   $(".animsition").animsition({
    inClass: 'fade-in',
    outClass: 'fade-out',
    inDuration: 1500,
    outDuration: 800,
    linkElement: '.animsition-link',
    // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
    loading: true,
    loadingParentElement: 'body', //animsition wrapper element
    loadingClass: 'animsition-loading',
    loadingInner: '', // e.g '<img src="loading.svg" />'
    timeout: false,
    timeoutCountdown: 5000,
    onLoadEvent: true,
    browser: [ 'animation-duration', '-webkit-animation-duration'],
    // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
    // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
    overlay : false,
    overlayClass : 'animsition-overlay-slide',
    overlayParentElement : 'body',
    transition: function(url){ window.location.href = url; }
  });
  
  $(".animsition-overlay").animsition({
    inClass: 'overlay-slide-in-right',
    outClass: 'overlay-slide-out-right',
    inDuration: 800,
    outDuration: 800,
    //linkElement: '.aa',
    linkElement: 'a:not([target="_blank"]):not([href^="#"])',
    loading: true,
    loadingParentElement: 'body', //animsition wrapper element
    loadingClass: 'animsition-loading',
    loadingInner: '', // e.g '<img src="loading.svg" />'
    timeout: false,
    timeoutCountdown: 5000,
    onLoadEvent: true,
    browser: [ 'animation-duration', '-webkit-animation-duration'],
    // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
    // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
    overlay : true,
    overlayClass : 'animsition-overlay-slide',
    overlayParentElement : 'body',
    transition: function(url){ window.location.href = url; }
  });
*/

   //HAMBURGUESA
   $('.hamburguesa').on('click', function() {
     $('body').toggleClass('actiu');
   });
   $('.site-main').on('click', function() {
     $('body').removeClass('actiu');
   });

   //GRID PROJECTES
   var $grid = $('.grid').imagesLoaded(function() {
     // Inicia Masonry un cop les imatges han estat carregades
     $grid.masonry({
       itemSelector: '.grid-item',
       columnWidth: '.grid-sizer',
       gutter: '.gutter-sizer',
       percentPosition: true
     });
   });

   //GRID PROJECTE
   
   var $gridprojecte = $('.grid--projecte').imagesLoaded(function() {
     // Inicia Masonry un cop les imatges han estat carregades
     $gridprojecte.masonry({
       itemSelector: '.grid--item-projecte',
       columnWidth: '.grid--sizer-projecte',
       gutter: '.gutter--sizer-projecte',
       percentPosition: true
     });
   });
   

   //GRID STAFF
   var $gridstaff = $('.grid-staff').imagesLoaded(function() {
     // Inicia Masonry un cop les imatges han estat carregades
     $gridstaff.masonry({
       itemSelector: '.grid-item-staff',
       columnWidth: '.grid-sizer-staff',
       gutter: '.gutter-sizer-staff',
       percentPosition: true
     });
   });

   //Efecte projectes
   /*
   $('.grid-item.clic').on('mouseover', function(){
     $('.grid-item.clic').addClass('opacitat');
     $(this).removeClass('opacitat');
   });
   $('.grid-item.clic').on('mouseout', function(){
     $('.grid-item.clic').removeClass('opacitat');
   });
   */

   //VIEWPORT CHECKER
   $('.titol-principal, .subtitol, .fitxa-tecnica dl, .descripcio, .destacat.primer, .fitxa h2, .fitxa p, .staff-text .destacat, .grup h3, .grup ul, .grup p, .vols-treballar .destacat, .vols-treballar > p, .formulari, .titol-servei, .titol-servei + div, .page-template-serveis .destacat, .subtitol-servei, .subtitol-servei + p, .paissatge p, .page-template-contactar dt, .page-template-contactar dd, .grid-item-staff, .urbanisme h3, .serveis-n .titol-servei, .edificacio-n .titol-servei + div, .serveis-n .destacat').addClass('hidden').viewportChecker({
     classToAdd: 'visible',
     offset: 10
   });
   
   $('.main-image, .grid-item, .grid--item-projecte, .jumbo-imatge, .fitxa-imatge, .staff-img, .vols-treballar, .img-edificacio, .urbanisme .img-urbanisme, .img-espai, .img-urbanisme').viewportChecker({
     classToAdd: 'visible'
     //offset: 10
   });

   $('.site-footer').viewportChecker({
    classToAddForFullView: 'visible',
    offset: 10,
    repeat: true
   });

   //tot clicable
   
   $(".clic").on('click', function() {
     window.location = $(this).find("a").attr("href");
     return false;
   });
   

   //Amagar Header scroll
   var didScroll;
   var lastScrollTop = 0;
   var delta = 5;
   var navbarHeight = $('.site-header').outerHeight();

   $(window).on('scroll', function(event){
       didScroll = true;
   });

   setInterval(function() {
       if (didScroll) {
           hasScrolled();
           didScroll = false;
       }
   }, 250);

   function hasScrolled() {
       var st = $(this).scrollTop();

       // Make sure they scroll more than delta
       if(Math.abs(lastScrollTop - st) <= delta)
           return;

       // If they scrolled down and are past the navbar, add class .nav-up.
       // This is necessary so you never see what is "behind" the navbar.
       if (st > lastScrollTop && st > navbarHeight){
           // Scroll Down
           $('.site-header').removeClass('mostrar').addClass('amagar');
       } else {
           // Scroll Up
           if(st + $(window).height() < $(document).height()) {
               $('.site-header').removeClass('amagar').addClass('mostrar');
           }
       }

       lastScrollTop = st;
   }

   // Mostrar la navegació entre post al fer scroll
   $(window).scroll(function() {    
      var scroll = $(window).scrollTop();

      if (scroll >= 400) {
          $(".prev-pro, .next-pro").addClass("visible");
      } else {
          $(".prev-pro, .next-pro").removeClass("visible");
      }
  });

 })(jQuery, this);